// material
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// components
import { Box, Card, Table, Stack, TableRow, TableBody, TableCell, Container, Typography, TableContainer, useTheme, TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, BookingMoreMenu } from '../sections/@dashboard/user';
import SnackBar from '../components/Snackbar';
import { APIGetAllBookings, APIRefundBookings } from '../utils/apiCalled';
import { fDateTime } from '../utils/formatTime';
import { capitalCase } from 'change-case';
import CustomPagination from 'src/components/CustomPagination';
import MoreBookings from 'src/components/dashboard/MoreBookings';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';import BookingsPagination from 'src/components/BookingsPagination';
;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array); // query search box for trip ID and transaction ID
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'tripId', label: 'Trip ID', alignRight: false },
  { id: 'transactionId', label: 'Transaction ID', alignRight: false },
  { id: 'createdAt', label: 'Booking Time', alignRight: false },
  { id: 'zoneName', label: 'Zone', alignRight: false },
  { id: '' }
];

const tableStyle = {
  border: '0.063rem solid #EAECF0',
  borderTop: '0.094rem solid #EAECF0',
  borderBottom: '0.094rem solid #EAECF0',
  boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
  borderRadius: '0.75rem',
};

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const bookingOptions = ['completed', 'cancelled', 'failed_to_board', 'timed_out'];

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // this to store the data when click one of the row
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [mainMsg, setMainMsg] = useState(''); // this state is for the main snackbar message
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [getBookings, setGetBookings] = useState([])
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)
  const [totalBookings, setTotalBookings] = useState(0)
  const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
  const [openMoreBookings, setOpenMoreBookings] = useState(false); // Open the more bookings page
  const [selectedBooking, setSelectedBooking] = useState(false); // Set the selected booking
  const [selectedTab, setSelectedTab] = useState(0); // Set the selected tab which is open
  const [maxAmount, setMaxAmount] = useState(0); // Store the max Amount of refund available

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState(bookingOptions);
  const [returnedBookings, setReturnedBookings] = useState(null);

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // apicalled for getAdminUser
  const getAPICalled = async (pageNumber, bookingsPerPage, searchString, from, to, status) => {
    try {
      const result = await APIGetAllBookings(setLoading, pageNumber + 1, bookingsPerPage, searchString, from, to, status);
      if (result.status === 200) {
        const { data } = result.data;
        const totalBookings = result.data.totalBookings
        setGetBookings(data);
        setTotalBookings(totalBookings);
        setReturnedBookings(result.data.data.length)
        setLoading(false);
      }
      else if (result.response.data.status === 404) {
        setLoading(false);
        setOpen(true);
        setGetBookings([]);
        setTotalBookings(0);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error! ' + result.response.data.message);
      }
      else {
        setLoading(false);
        setOpen(true);
        setGetBookings([]);
        setTotalBookings(0);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error! Cannot get bookings from database.');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setGetBookings([]);
      setTotalBookings(0);
      setVariant('error');
      setMainMsg('Error Encountered')
      setErrMsg('Error!', err);
    }
  };

  // API call for manual refund
  const refundAPICall = async (refundValue) => {
    try {
      const result = await APIRefundBookings(setLoading, refundValue);
      if (result.status === 200) {
        setLoading(false);
        getAPICalled(page, rowsPerPage); // Call API again to display newly updated value
        setOpen(true);
        setVariant('success');
        setMainMsg('Booking Refund Successful')
        setSuccMsg('Booking with tripID ' + refundValue.tripId + ' is successfully refunded');
        setOpenMoreBookings(false);
        setSelectedBooking(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Booking Refund Unsuccessful')
        setErrMsg(result.response.data.message)
        setOpenMoreBookings(false);
        setSelectedBooking(false);
        // setErrMsg('Unable to refund booking.'); // handle other error codes
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setMainMsg('Booking Refund Unsuccessful')
      setErrMsg('Error!', err);
      setOpenMoreBookings(false);
      setSelectedBooking(false);
    }
  };

  // this to called api first page load
  useEffect(() => {
    // console.log('First page load useEffect ' + rowsPerPage)
    getAPICalled(page, rowsPerPage, filterName, fromDate, toDate, bookingStatus);
  }, [])

  // this after click submit will called back the getVehicle API
  useEffect(() => {
    if (modalTriggerAPICalled) {
      // console.log('useEffect2 ')
      getAPICalled(page, rowsPerPage, filterName, fromDate, toDate, bookingStatus);
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  // this useEffect for call the searchAsset API called. Interval for 0.6 sec
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // this to clear time out
    }
    setSearchTimeout( // to buffer the search by 0.6 second
      setTimeout(() => {
        getAPICalled(page, rowsPerPage, filterName, fromDate, toDate, bookingStatus); // call the api
      }, 600),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getBookings.map((n) => n.userName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => { // To change page when clicking left/ right button
    setPage(newPage);
    setModalTriggerAPICalled(true)
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    getAPICalled(page, rowsPerPage, filterName, fromDate, toDate, bookingStatus);
    setPage(0);
  };

  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };

  // Added Open More Bookings
  const handleOpenMoreBookings = () => {
    setOpenMoreBookings(!openMoreBookings);
  }

  // Added Handle Open Details
  const handleOpenDetails = (booking) => {
    setSelectedBooking(booking);
    setOpenMoreBookings(!openMoreBookings);
    setSelectedTab(0);
    handleCalculateMaxAmount(booking);
  }

  // Added Handle Open Refund
  const handleOpenRefund = (booking) => {
    setSelectedBooking(booking);
    setOpenMoreBookings(!openMoreBookings);
    setSelectedTab(1);
  }

  const handleCalculateMaxAmount = (booking) => {
    const calculatedMaxAmount = booking.paymentAmount - booking.refundAmount - booking.manualRefundAmount
    const roundedMaxAmount = Math.round(calculatedMaxAmount * 100) / 100
    setMaxAmount(roundedMaxAmount)
  }

  const handleFromDateChange = (newDate) => {
    if (newDate instanceof Date && !isNaN(newDate)) { // Check if newDate is a valid Date object
      const formattedDate = newDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD
      setFromDate(formattedDate);
    } else {
      setFromDate(null);
    }
  };


  const handleToDateChange = (newDate) => {
    if (newDate instanceof Date && !isNaN(newDate)) {
      const formattedDate = newDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD
      setToDate(formattedDate)
    }
    else {
      setToDate(null);
    }
  };

  const handleFilter = () => {
    getAPICalled(page, rowsPerPage, filterName, fromDate, toDate, bookingStatus);
    setPage(0);
  }

  const handleBookingStatusChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(value); // Update selected options

    // Join selected options with commas
    const statusString = value.join(',');
    setBookingStatus(statusString);
  };

  const formatOption = (option) => {
    return option
      .replace(/_/g, ' ')               // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalBookings) : 0;
  const filteredUsers = applySortFilter(getBookings, getComparator(order, orderBy), filterName); //orderr = asc, orderBy = name
  return (
    <Page title="Bookings">
      <Container maxWidth="xl" sx={{ mt: !openMoreBookings ? 12 : 0 }}>
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
          mainMsg={mainMsg}
        />
        {!openMoreBookings ? <>
          <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Typography variant="h3">Bookings</Typography>
            <Typography variant='body1' sx={{ color: theme.palette.grey[700] }} >Manage Trek Rides bookings and perform refunds</Typography>
          </Box>
          <Box>
            <Box sx={tableStyle}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <UserListToolbar
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  labelName="Search by Trip Id or Transaction Id"
                />

              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={totalBookings}
                      numSelected={selected.length}
                      // onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {
                        filteredUsers.map((s) => {
                          return (
                            <TableRow
                              hover
                              key={s._id}
                              tabIndex={-1}
                              role="checkbox"
                            >
                              <TableCell align="left" component="th" scope="row" onClick={() => handleOpenDetails(s)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant='body2' sx={{ color: theme.palette.grey[900] }}>{s.userName}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[700] }}>{s.email}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{capitalCase(s.status.replace(/_/g, " "))}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{s.tripId}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{typeof (s.transactionId) === 'string' ? s.transactionId : 'None'}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{fDateTime(s.createdAt)}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenDetails(s)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{s.zoneName}</Typography></TableCell>
                              <TableCell align="right">
                                <BookingMoreMenu
                                  handleCalculateMaxAmount={handleCalculateMaxAmount}
                                  handleDetails={handleOpenDetails} //Added
                                  handleOpenRefund={handleOpenRefund} //Added
                                  bookingList={s}
                                  handleConfirm={refundAPICall}
                                  setModalTriggerAPICalled={setModalTriggerAPICalled}
                                  setSuccMsg={setSuccMsg}
                                  setErrMsg={setErrMsg}
                                  setMainMsg={setMainMsg}
                                  setVariant={setVariant}
                                  setOpen={setOpen}
                                  loading={loading}
                                  maxAmount={maxAmount}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <BookingsPagination page={page} returnedBookings={returnedBookings} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} />
            </Box>
          </Box> </> : <MoreBookings booking={selectedBooking} handleOpenMoreBookings={handleOpenMoreBookings} handleConfirm={refundAPICall} selectedTab={selectedTab} setSelectedTab={setSelectedTab} maxAmount={maxAmount} setMaxAmount={setMaxAmount} />}
        {loading && <CircularProgress color='success' sx={loadingStyle} />}
      </Container>
    </Page>
  );
}