import React, { useRef, useState, useEffect } from 'react';
import { Box, Card, Container, Typography, useTheme } from '@mui/material';
import Page from '../components/Page';

const url = process.env.REACT_APP_MORPHEUS_EYEFRAME;

const tableStyle = {
    border: '0.063rem solid #EAECF0',
    borderTop: '0.094rem solid #EAECF0',
    borderBottom: '0.094rem solid #EAECF0',
    boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
    borderRadius: '0.75rem',
};

export default function Scheduling() {
    const theme = useTheme();
    const iframeRef = useRef(null);
    const [iframeUrl, setIframeUrl] = useState('');  // Track the current URL of the iframe

    // Function to handle iframe load
    const handleIframeLoad = () => {
        // Get the iframe URL after load
        const iframe = iframeRef.current;
        setIframeUrl(iframe.src);  // Store the URL of the iframe in the state
    };

    useEffect(() => {
        handleIframeLoad();
    }, []);

    return (
        <Page title="Driver Scheduling">
            <Container maxWidth="xl" sx={{ mt: 12 }}>
                <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <Typography variant="h3">Driver Scheduling</Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.grey[700] }}>
                        Schedule various drivers on Trek Rides
                    </Typography>
                </Box>
                <Card sx={{ ...tableStyle, width: '100%' }}>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <iframe
                            ref={iframeRef}
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '1600px',
                            }}
                            src={url}
                        ></iframe>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 7,
                                left: '45%',
                                width: '10%',
                                height: '4%',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                zIndex: 10,
                            }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    );
}
