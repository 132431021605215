import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Driver from './pages/Driver';
import Vehicle from './pages/Vehicle';
import User from './pages/User';
import VerificationModule from './pages/VerificationModule';
import Scheduling from './pages/Scheduling';
import NotFound from './pages/Page404';
import UserBookings from './pages/Bookings';
import { auth } from './firebase';
import { getFirstEnabledModule, usePrivilege } from './PrivilegeContext';
import ProtectedRoute from './ProtectedRoute';

// ----------------------------------------------------------------------

export default function Router() {

  const { role, modules } = usePrivilege();

  const firstModule = getFirstEnabledModule(modules);
  const fallbackPath = '/login';

  // const navigate = useNavigate();
  // const email = Cookies.get('email');
  // const [indicator, setIndicator] = useState({
  //   dashboard: false,
  //   login: false
  // })

  // to call settingPage
  // useEffect(() => {
  // this function is to check if the user authenticate or not.
  // if authenticate (have value in cookies) it will navigate to
  //  onAuthStateChanged(auth, (user) => {
  //    console.log('userss', user);
  //    if (user) {
  //      navigate('/dashboard/app', { replace: true });
  //      setIndicator({
  //       dashboard: true,
  //       login: false
  //      })
  //    } else {
  //     navigate('/login', { replace: true });
  //     setIndicator({
  //       dashboard: false,
  //       login: true
  //      })
  //    }
  //  })
  //  return () => {
  //   unlisten();
  //  }
  // }, []);

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'drivers', element: (
            <ProtectedRoute allowedRoles={[900, 101, 111, 110]} allowedModuleKey="driversModule" role={role} modules={modules}>
              <Driver />
            </ProtectedRoute>
          )
        },
        {
          path: 'vehicles', element: (
            <ProtectedRoute allowedRoles={[900, 101, 111, 110]} allowedModuleKey="vehiclesModule" role={role} modules={modules}>
              <Vehicle />
            </ProtectedRoute>
          )
        },
        {
          path: 'users', element: (
            <ProtectedRoute allowedRoles={[900, 101]} role={role} modules={modules}>
              <User />
            </ProtectedRoute>
          )
        },
        {
          path: 'verification', element: (
            <ProtectedRoute allowedRoles={[900, 101, 111, 110]} allowedModuleKey="verificationModule" role={role} modules={modules}>
              <VerificationModule />
            </ProtectedRoute>
          )
        },
        {
          path: 'scheduling', element: (
            <ProtectedRoute allowedRoles={[900, 101, 111, 110]} allowedModuleKey="schedulingModule" role={role} modules={modules}>
              <Scheduling />
            </ProtectedRoute>
          )
        },
        {
          path: 'bookings', element:
            <ProtectedRoute allowedRoles={[900, 101, 111, 110]} allowedModuleKey="bookingsModule" role={role} modules={modules}>
              <UserBookings />
            </ProtectedRoute>
        },
        {
          path: '/dashboard',
          element: <Navigate to={`/dashboard/${firstModule?.key || fallbackPath}`} />
        }
      ]
    },
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to={`/dashboard/${firstModule?.key || fallbackPath}`} /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
