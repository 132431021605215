import { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import ModalDelete from 'src/components/ModalDelete';
import { usePrivilege } from 'src/PrivilegeContext';
import { Context } from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

export default function AdminMoreMenu({ handleEditOpen, data, handleDeleteOpen, handleClose, deleteModal, handleDelete, handleSeeMore }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const { role } = usePrivilege();

    const [mainName, setMainName] = useContext(Context);

    const fullName = `${data?.firstName} ${data?.lastName}`;

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleSeeMore(data)}>
                    <ListItemIcon>
                        <Iconify icon="heroicons:signal-solid" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="See More" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                {(role === 900 || data?.privileges.role !== 900) && (
                    <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleEditOpen(data)}>
                        <ListItemIcon>
                            <Iconify icon="eva:edit-fill" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                )}
                {data?.privileges?.role !== 900 && fullName !== mainName && (
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDeleteOpen()}>
                        <ListItemIcon>
                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                )}
                <ModalDelete
                    handleDelete={handleDelete}
                    deleteModal={deleteModal}
                    data={data}
                    handleClose={handleClose}
                />
            </Menu>
        </>
    );
}
