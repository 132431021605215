import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Button,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
  Box,
  Typography,
  useTheme,
  alpha,
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 340,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 400, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const ButtonStyle = styled(Button)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginRight: theme.spacing(2),
  justifyContent: 'space-between',
  color: theme.palette.grey[700],
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[500], 0.08), // Adding opacity here using alpha()
  },
}));




// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, labelName, popupfilter, displayFilter }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {
        !displayFilter ? (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={labelName}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        ) : (
          <Box />
        )
      }
      {
        displayFilter && (
            <ButtonStyle onClick={(event) => popupfilter(event.currentTarget)} >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Iconify icon="ic:round-filter-list" width={24} height={24} />
                <Typography sx={{ fontWeight: 'fontWeightMedium', ml: 1 }}>Filters</Typography>
              </Box>
            </ButtonStyle>
        )}
    </RootStyle>
  );
}
