import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Typography, useTheme, alpha } from '@mui/material';
// components
import Page from '../components/Page';

export default function Page404() {

  const theme = useTheme();

  const buttonStyle = {
    border: '1px solid',
    fontSize: '18px',
    borderColor: 'grey.300',
    px: 20,
    pt: 1,
    pb: 1,
    mr: 2,
    color: theme.palette.grey[700],
    backgroundColor: 'background.paper',
    '&:hover': {
      bgcolor: alpha(theme.palette.grey[500], 0.08),
    },
  };

  return (
    <Page title="404 Error">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          minHeight: '100vh',
          px: 12,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 1.5,
            mt: 15,
            ml: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'fontWeightMedium' }}>
            404 error
          </Typography>
          <Typography variant="h1" sx={{ fontSize: '3rem', fontWeight: 'fontWeightMedium' }}>
            Page not found
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.3rem', mb: 4 }}>
            Sorry, the page you are looking for doesn't exist.
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Button
              to="/"
              component={RouterLink}
              sx={{
                ...buttonStyle,
                width: '100%',
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            ml: 10,
          }}
        >
          <img
            src="/static/icons/errorPerson.svg"
            alt="Farhan"
            style={{
              maxWidth: '80%',
              height: 'auto',
            }}
          />
        </Box>

        <Typography
          sx={{
            fontSize: '14px',
            color: theme.palette.grey[600],
            position: 'absolute',
            bottom: 16,
            left: 16,
          }}
        >
          Copyright © {new Date().getFullYear()} Asia Mobility Technologies Sdn Bhd. All Rights Reserved.
        </Typography>
      </Box>
    </Page>
  );
}
