import { alpha, Box, Button, FormControl, TextField, Typography, useTheme } from "@mui/material";
import BackButton from "../backButton";
import ModalDelete from "../ModalDelete";
import { useContext } from "react";
import { Context } from "src/layouts/dashboard";

export default function MoreUsers({ handleCloseMoreUsers, user, handleDelete, deleteModal, handleOpenDeleteModal, handleCloseDeleteModal, handleOpenPageUsers, role }) {

    const theme = useTheme();

    const [mainName, setMainName] = useContext(Context);

    const fullName = `${user?.firstName} ${user?.lastName}`;

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const editButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const removeButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.error.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.error.main, 0.85)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    const convertAccessPrivilege = (e) => {

        switch (e) {
            case 900:
                return 'Owner'
            case 101:
                return 'Admin'
            case 111:
                return 'Editor'
            case 110:
                return 'Viewer'
            default:
                return 'None'
        }
    };

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <BackButton back={handleCloseMoreUsers} type={'user'} />
            </Box>
            <Box sx={{ mt: 1, pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <Typography variant="h3">Admin Users</Typography>
                <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>View all details of the admin user</Typography>
            </Box>
            <Box sx={{ padding: 2, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                <Box sx={{ width: '70%', mt: 2 }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>First Name</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.firstName ? user.firstName : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Last Name</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.lastName ? user.lastName : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Email</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.email ? user.email : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Phone</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.phone ? user.phone : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Firebase UUID</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.firebaseUUID ? user.firebaseUUID : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Agency</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.agency ? user.agency : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Access Privileges</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.privileges.role ? convertAccessPrivilege(user.privileges.role) : 'None'} />
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'right', borderTop: '1px solid', borderColor: theme.palette.grey[300], pt: 3 }}>
                <Button sx={buttonStyle} onClick={() => handleCloseMoreUsers()}>
                    <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                        Cancel
                    </Typography>
                </Button>
                {((role === 900 && user.privileges.role === 900) ||
                    ((role === 900 || role === 101) && user.privileges.role !== 900)) && (
                        <>
                            {fullName !== mainName && (
                                <Button sx={removeButtonStyle} onClick={() => handleOpenDeleteModal()}>
                                    <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                        Remove User
                                    </Typography>
                                </Button>
                            )}
                            <Button sx={editButtonStyle} onClick={() => handleOpenPageUsers(user)}>
                                <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                    Edit
                                </Typography>
                            </Button>
                        </>
                    )}
            </Box>
            {deleteModal && <ModalDelete deleteModal={deleteModal} handleDelete={handleDelete} handleClose={handleCloseDeleteModal} data={user} />}
        </>
    )
}