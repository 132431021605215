import { useContext, useEffect, useState } from "react";
import BackButton from "../backButton";
import { alpha, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { APICreateUser, APIUpdateUser } from "src/utils/apiCalled";
import MuiPhoneNumber from "material-ui-phone-number";
import { Context } from "src/layouts/dashboard";
import Cookies from "js-cookie";

export default function PageUser({ handleCloseMoreUsers, user, setOpen, setSuccMsg, setErrMsg, setVariant, setMainMsg, setModalTriggerAPICalled, role }) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [firebaseUUID, setFirebaseUUID] = useState('');
    const [agency, setAgency] = useState('');
    const [loading, setLoading] = useState(false);
    const [posLoading, setPosLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [formChanged, setFormChanged] = useState(false);

    // Privilege data
    const [accessPrivileges, setAccessPrivileges] = useState('');
    const [moduleBookings, setModuleBookings] = useState(false);
    const [moduleDrivers, setModuleDrivers] = useState(false);
    const [moduleVehicles, setModuleVehicles] = useState(false);
    const [moduleVerification, setModuleVerification] = useState(false);
    const [moduleScheduling, setModuleScheduling] = useState(false);
    const [moduleError, setModuleError] = useState('');

    const [mainName, setMainName] = useContext(Context)

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName || '');
            setLastName(user.lastName || '');
            setEmail(user.email || '');
            setPhone(user.phone || '');
            setFirebaseUUID(user.firebaseUUID || '');
            setAgency(user.agency || '');
            setAccessPrivileges(user.privileges?.role || null);
            setModuleBookings(user.privileges?.module.bookingsModule.bookings || false)
            setModuleDrivers(user.privileges?.module.driversModule.drivers || false)
            setModuleVehicles(user.privileges?.module.vehiclesModule.vehicles || false)
            setModuleVerification(user.privileges?.module.verificationModule.verification || false)
            setModuleScheduling(user.privileges?.module.schedulingModule.scheduling || false)
        }
    }, [user])

    useEffect(() => {
        const sanitizedPhone = phone.startsWith('+') ? phone.replace('+', '') : phone;

        const userData = {
            firstName,
            lastName,
            firebaseUUID,
            agency,
            privileges: {
                role: accessPrivileges,
                module: {
                    bookingsModule: {
                        bookings: moduleBookings
                    },
                    driversModule: {
                        drivers: moduleDrivers
                    },
                    vehiclesModule: {
                        vehicles: moduleVehicles
                    },
                    verificationModule: {
                        verification: moduleVerification
                    },
                    schedulingModule: {
                        scheduling: moduleScheduling
                    }
                }
            },
            ...(user ? {} : { email, phone: sanitizedPhone }) // Include email and phone only if user is undefined
        };
        setUserData(userData);

    }, [
        firstName,
        lastName,
        email,
        phone,
        firebaseUUID,
        agency,
        accessPrivileges,
        moduleBookings,
        moduleDrivers,
        moduleVehicles,
        moduleVerification,
        moduleScheduling,
        user // Add user to dependencies to track changes
    ]);


    const theme = useTheme();

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',

    }

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.main,
            color: 'white',
            opacity: '0.6',
        },
    }

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormChanged(true);

        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);

                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (!emailRegex.test(value)) {
                    setEmailError(true);
                    setEmailErrorMessage('Please enter a valid email address.');
                } else {
                    setEmailError(false);
                    setEmailErrorMessage('');
                }
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'firebaseUUID':
                setFirebaseUUID(value);
                break;
            case 'agency':
                setAgency(value);
                break;
            case 'accessPrivileges':
                setAccessPrivileges(value);
                break;
            case 'moduleBookings':
                setModuleBookings(checked);
                break;
            case 'moduleDrivers':
                setModuleDrivers(checked);
                break;
            case 'moduleVehicles':
                setModuleVehicles(checked);
                break;
            case 'moduleVerification':
                setModuleVerification(checked);
                break;
            case 'moduleScheduling':
                setModuleScheduling(checked);
                break;
            default:
                break;
        }
    };


    const removeAllState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setFirebaseUUID('');
        setAgency('');
        setAccessPrivileges('');
        setModuleBookings(false);
        setModuleDrivers(false);
        setModuleVehicles(false);
        setModuleVerification(false);
        setModuleScheduling(false);
    };

    const closeUsers = () => {
        removeAllState();
        handleCloseMoreUsers();
    }

    const apiAddEditCalled = async (value, id) => {
        try {
            if (user && user._id) {
                const result = await APIUpdateUser(setPosLoading, id, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('User ' + value.firstName + ' ' + value.lastName + ' has sucessfully been edited');
                    setMainMsg('User Successfully Updated');
                    removeAllState();
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('error')
                    setErrMsg('Failed to update user')
                    setMainMsg('User Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            } else {
                const result = await APICreateUser(setPosLoading, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setMainMsg('User Successfully Created');
                    setSuccMsg('User ' + value.firstName + ' ' + value.lastName + ' has sucessfully been created');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('error')
                    setErrMsg('Failed to create user')
                    setMainMsg('User Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            }
        } catch (err) {
            setPosLoading(false);
            removeAllState();
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err.message || 'Please try again'}`);
            setMainMsg('Error Encountered')
        }
    }

    const handleSubmit = () => {
        if (disabledButton) {
            setOpen(true);
            setVariant('error');
            setErrMsg('Please fill all required fields');
            return;
        }

        apiAddEditCalled(userData, user ? user._id : null);
        if (user.email === Cookies.get('email')) {
            setMainName(`${userData.firstName} ${userData.lastName}`);
        }
        removeAllState();
        handleCloseMoreUsers();
    };

    useEffect(() => {
        // Check if at least one module is selected
        const isAnyModuleSelected =
            moduleBookings ||
            moduleDrivers ||
            moduleVehicles ||
            moduleVerification ||
            moduleScheduling;

        // Display error message if no module is selected
        if (!isAnyModuleSelected) {
            setModuleError("Please select at least one module.");
        } else {
            setModuleError(""); // Clear error if a module is selected
        }
    }, [
        moduleBookings,
        moduleDrivers,
        moduleVehicles,
        moduleVerification,
        moduleScheduling
    ]);

    useEffect(() => {
        // Check if all required fields are filled
        const isFormFilled =
            firstName &&
            lastName &&
            email &&
            phone &&
            firebaseUUID &&
            accessPrivileges &&
            agency &&
            (moduleBookings || moduleDrivers || moduleVehicles || moduleVerification || moduleScheduling); // Ensure at least one module is selected

        // Check if the role is unchanged
        const isRoleUnchanged = user.privileges?.role === accessPrivileges;

        // Check if the modules are unchanged
        const areModulesUnchanged =
            JSON.stringify(user.privileges?.module) ===
            JSON.stringify({
                moduleBookings,
                moduleDrivers,
                moduleVehicles,
                moduleVerification,
                moduleScheduling,
            });

        // Check if the entire form is unchanged (no changes in any field)
        const isFormUnchanged =
            user &&
            firstName === user.firstName &&
            lastName === user.lastName &&
            email === user.email &&
            phone === user.phone &&
            firebaseUUID === user.firebaseUUID &&
            agency === user.agency &&
            isRoleUnchanged &&
            areModulesUnchanged;

        // Disable the button if the form is either not filled, the data has not changed, or `formChanged` is false
        setDisabledButton(!isFormFilled || isFormUnchanged || !formChanged);
    }, [
        firstName,
        lastName,
        email,
        phone,
        firebaseUUID,
        agency,
        accessPrivileges,
        moduleBookings,
        moduleDrivers,
        moduleVehicles,
        moduleVerification,
        moduleScheduling,
        formChanged,
        user, // Add user to dependencies to track changes
    ]);




    const handlePhoneChange = (value) => {
        setPhone(value);

        const digits = value.replace(/\D/g, '');
        if (digits.length < 8 || digits.length > 12) {
            setPhoneError(true);
            setPhoneErrorMessage('Phone number must be between 8 to 12 digits.');
        } else {
            setPhoneError(false);
            setPhoneErrorMessage('');
        }
    };

    useEffect(() => {
        if (!user) {
            if ((accessPrivileges === 900) || (accessPrivileges === 101)) {
                setModuleBookings(true);
                setModuleDrivers(true);
                setModuleVehicles(true);
                setModuleVerification(true);
                setModuleScheduling(true);
            }
            else {
                setModuleBookings(false);
                setModuleDrivers(false);
                setModuleVehicles(false);
                setModuleVerification(false);
                setModuleScheduling(false);
            }
        } else {
            if ((accessPrivileges === 900) || (accessPrivileges === 101)) {
                setModuleBookings(true);
                setModuleDrivers(true);
                setModuleVehicles(true);
                setModuleVerification(true);
                setModuleScheduling(true);
            }
        }
    }, [user, accessPrivileges])

    return (
        <>
            <Box sx={{ mt: 2 }}></Box>
            <BackButton back={handleCloseMoreUsers} type={'user'} />
            <Box sx={{ mt: 2, pb: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h4">{user ? 'Edit Admin User' : 'Create Admin User'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button sx={buttonStyle} onClick={() => closeUsers()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button sx={confirmButtonStyle} disabled={disabledButton} onClick={() => handleSubmit(userData)}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Confirm
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <Box sx={{ padding: 1, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                <Box sx={{ width: '70%', mt: 2 }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="First Name" name="firstName" value={firstName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="Last Name" name="lastName" value={lastName} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Email <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField disabled={!!user} label="Email" name="email" value={email} onChange={handleInputChange} sx={{ flexGrow: 1 }} error={emailError} helperText={emailError ? emailErrorMessage : ''} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Phone Number <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        {user ? (
                            <TextField disabled={!!user} label="Phone Number" name="phone" value={phone} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        ) : (
                            <MuiPhoneNumber
                                label="Phone Number"
                                disabled={!!user}
                                autoFormat={false}
                                variant="outlined"
                                name="phone"
                                defaultCountry="my" // Set default country as needed
                                value={phone}
                                onChange={handlePhoneChange} // Phone input change handler
                                sx={{ flexGrow: 1 }}
                                error={phoneError}  // Show error style if phone is invalid
                                helperText={phoneError ? phoneErrorMessage : ''}  // Show error message
                            />
                        )}
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Firebase UUID <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="Firebase UUID" name="firebaseUUID" value={firebaseUUID} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Agency <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField label="Agency" name="agency" value={agency} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                    </FormControl>
                    <Box sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            Access Privileges <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel>Access Privileges</InputLabel>
                            <Select
                                label="Access Privilges"
                                name="accessPrivileges"
                                value={accessPrivileges}
                                onChange={handleInputChange}
                                sx={{ flexGrow: 1 }}
                            >
                                {role === 900 && <MenuItem value={900}>Owner</MenuItem>}
                                <MenuItem value={101}>Admin</MenuItem>
                                <MenuItem value={111}>Editor</MenuItem>
                                <MenuItem value={110}>Viewer</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {((accessPrivileges === 111) || (accessPrivileges === 110)) && (
                        <Box sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Viewing Modules <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <FormGroup>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '60%' }}>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={moduleBookings}
                                                    onChange={handleInputChange}
                                                    name="moduleBookings"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                />
                                            }
                                            label="Bookings"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={moduleDrivers}
                                                    onChange={handleInputChange}
                                                    name="moduleDrivers"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                />
                                            }
                                            label="Drivers"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={moduleVehicles}
                                                    onChange={handleInputChange}
                                                    name="moduleVehicles"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                />
                                            }
                                            label="Vehicles"
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={moduleVerification}
                                                    onChange={handleInputChange}
                                                    name="moduleVerification"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                />
                                            }
                                            label="E-KYC Verification"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={moduleScheduling}
                                                    onChange={handleInputChange}
                                                    name="moduleScheduling"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                />
                                            }
                                            label="Driver Scheduling"
                                        />
                                    </Box>
                                </Box>
                                {moduleError && <FormHelperText error>{moduleError}</FormHelperText>}
                            </FormGroup>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}